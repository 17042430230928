.markdown {
  pre, p, ul, ol, blockquote {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    padding-left: 20px;
  }
  li {
    padding-left: 4px;
  }
}
