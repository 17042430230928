.markdown pre:first-child, .markdown p:first-child, .markdown ul:first-child, .markdown ol:first-child, .markdown blockquote:first-child {
  margin-top: 0;
}

.markdown pre:last-child, .markdown p:last-child, .markdown ul:last-child, .markdown ol:last-child, .markdown blockquote:last-child {
  margin-bottom: 0;
}

.markdown ul {
  padding-left: 20px;
}

.markdown li {
  padding-left: 4px;
}

/*# sourceMappingURL=index.80919bb6.css.map */
